import './Game.css';
import React from 'react';

function Games () {
    return (
    <div className="Games">
        <div className="GameHeader">Games</div>
        <div className="GameSection">
            <div className="GameSectionHeader">Summation</div>
            <div className="GameSectionDescription">
                <p>
                Summation is a fast-paced arithmetic game that challenges players to quickly add two numbers to reach a target sum between 9 and 14. But there's a twist: the numbers can be presented in decimal, word, tally, or Roman numeral format!
                </p>
                <p>
                Players race against the clock to make as many sums as possible. With each correct answer, the player gains more SUM$. The higher the players SUM$ and levels completed the higher the player's ranking.
                </p>
                <p>Can you master the mental math and conquer the diverse number formats?
                </p>
            </div>
            <div className="GameSectionInfo">
                <p>Coming in the Fall of 2024!</p>
                <p className="GameSectionComingSoon">Tutorials coming soon.</p>
            </div>
        </div>
    </div>);
}

export default Games;
